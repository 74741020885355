.btn {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-weight: 400;
    line-height: 1.6;
    height: min-content;
    color: #495057;
    text-align: center;
    vertical-align: middle;
    text-transform: uppercase;
    cursor: pointer;
    width: max-content;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .47rem .75rem;
    font-size: .875rem;
    border-radius: .25rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;

    &:disabled{
        color: lightgrey;
    }

    &.small-font{
        font-size: map-get($body-font-sm, "size");
        font-weight: map-get($body-font-sm, "weight");
        line-height: map-get($body-font-sm, "line-height");
    }

    
    .icon{
        margin-right: 0.5rem;
    }

    &-success{
        background-color: #33a186;
        border-color: #33a186;
        color: #fff;
        
        &:hover{
            background-color: darken(#33a186, 5);
        }
    }

    &-submit {
        background-color: #3980c0;
        border-color: #3980c0;
        color: #fff;

        &:hover{
            background-color: darken(#3980c0, 5);
        }

    }

    &-light {
        color: #000;
        background-color: #f5f6f8;
        border-color: #eee;
        
        &:hover{
            background-color: darken(#f5f6f8, 5);
        }
    }

    &.small {
        min-width: 55px;
    }
}



