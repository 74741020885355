.menu{
    display: block;
    opacity: 1;
    width: max-width;
    right: 0;
    top: 0;
    position: absolute;
    pointer-events: none;
}

div.user-info{
    border-bottom: 2px solid $border-color;
    padding: 0.5rem;

    .identity{
        font-size: 1rem;
    }

    .email {
        font-size: 0.6rem;
        color: map-get($brand-colors, 'secondary');
    }
}


.menu.active{
    position: absolute;
    opacity: 1;
    right: 0;
    bottom: 0;
    height: min-content;
    background-color: white;
    box-shadow: 0 5px 6px rgba(56,72,97,.1);
    border: 1px solid #e2e5e8;
    border-radius: 0.25rem;
    min-width: 12rem;
    pointer-events: auto; 
    z-index: 2;
}

.menu-item{
    display: block;
    width: 100%;
    font-weight: 400;
    color: #495057;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    text-decoration: none;
    outline: 0;
    padding: 0.6rem 1rem;

    &:hover{
        background-color: $border-color;
        cursor: pointer;
    }
}

.toggle-dropdown {
    text-align: center;
    color: #889;

    &:hover{
        color: map-get($brand-colors, "secondary");
        cursor: pointer;
    }
    
    svg {
        pointer-events: none;
    }
}

.toggle-dropdown.active{
    color: map-get($brand-colors, "secondary");
}