.settings-section{
    display: block;
    overflow: auto;
}

ul.group-items {
    list-style-type: none;
    padding: 0;

    li {
        border: 1px solid #ddd;
        padding: 0.5rem 1rem;
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        border-radius: 4px;
        align-items: center;

        &:hover{
            cursor: grab;
        }

        span {
            flex-grow: 1;
        }
        
        & > *:nth-child(1){
            margin-right: 16px;
        }

    }
}

.blacklist-items-wrapper{

    overflow: hidden;
    border-top-left-radius: 4.8px; 
    border-top-right-radius: 4.8px; 

    & > table {

        border: 1px solid red;
        width: 100%;
        border-spacing: 0;
        border: 1px solid #eff0f2;


        th {
            text-align: left;
            text-indent: 0.75rem;
            background-color: #f8f9fa;
            padding: 0; 
        }

        td, th {
            vertical-align: middle;
            border: 1px solid #eff0f2;
            padding: 0.25rem;
        }

        tr:nth-child(odd) > td {
            background-color: #f8f9fa;

        }

        tr > td:nth-child(1){
            width: 90%;
            text-indent: 0.75rem;
        }

        tr > td:nth-child(2){
            text-align: center;
            cursor: pointer;

            &:hover{
                color: map-get($brand-colors, "secondary");
                font-weight: 400;
            }
        }
    }


    & +  form {

        border-top: 2px solid #eee;

        .field {
            padding: 16px 0;
            justify-content: space-between;

            input {
                width: 75%;
            }

            @media(max-width: map-get($breakpoints, "medium")){
                flex-direction: row;
                flex-wrap: nowrap;
            }
        }

        .field > button {
            font-size: 18px;
            line-height: 1.2;
        }

        .field > input {
            margin: 0;
        }

        .field > label {
            min-width: max-content;
        }
    }
}