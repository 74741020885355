//
.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    font-size: map-get($body-font-sm, "size");
    margin-bottom: 1rem;

    .alert-msg{
        flex-grow: 1;
        text-align: left;
    }

    .alert-icon {
        box-sizing: content-box;
        padding-right: 0.375rem; 
    }

    &.inline{
        display: inline-block;
        padding: 0.25rem 0.5rem;
        width: max-content;
        margin: 0 1rem;
    }

}

.alert.alert-success{
    color: #1f6150;
    background-color: #d6ece7;
    border-color: #c2e3db;
}

.alert.alert-danger{
    color: #963b46;
    background-color: #fee0e3;
    border-color: #fed0d5;
}

.alert.alert-info{
    color: #2d6987;
    background-color: #dbeff9;
    border-color: #c9e7f6;
}



