@use "sass:color";
@use "sass:math";

@import "~destyle.css/destyle";
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@100;200;300;400;500;600;700;800;900&display=swap');

// EXTEND
@import 'variables';
@import 'alert';
@import 'form';
@import 'auth';
@import 'modal';
@import 'button';
@import 'menu';
@import 'table';
@import 'settings';
@import 'draggable';
@import 'details';
@import 'filters';
@import 'toggleLinks';

// GENERIC

*, ::after, ::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    user-select: none;
}

body {
    background: map-get($bg-colors, "body");
    padding: 0;
    margin: 0;
    font-family: map-get($body-font, "family");
    font-size: map-get($body-font, "size");
    font-weight: map-get($body-font, "weight");
    line-height: map-get($body-font, "line-height");
    color: map-get($body-font, "color");
    overflow-x: hidden;

    &.modal-open{
        overflow: hidden !important;
    }
}

h1, h2, h3, h4, h5, h6 {
    padding: 0;
    margin: 0;
}

h4 {
    line-height: 21.6px;
    font-size: 18px;
    font-weight: 700;
}

h5{
    font-size: 17.5px;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    color: map-get($brand-colors, "secondary");
}

// UTILS
.hidden {
    display: none;
}

.content-section{
    border-radius: 0.3rem;
    overflow: hidden;
    border: 1px solid #eff0f2;
    position: relative;
}

// MAIN LAYOUT COMPONENTS

#layout-wrapper{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 100vh;
}

#page-header{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 70px;
    background-color: map-get($bg-colors, "header");
    z-index: 1;
    color: #fff;
    padding: map-get($paddings, "header");
}

.header-section{

    display: flex;
    align-items: center;

    div.avatar{
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid rgba(255,255,255,.25);
        color: white;
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.header-wrapper{

    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(min-width: map-get($breakpoints, "x-large")){
        width: 85%;
        margin-left: auto;
        margin-right: auto;
    }
}

.settings-trigger{
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid rgba(255,255,255,.25);
    padding: 3px;
    position: relative;
    margin-right: 16px;
    cursor: pointer;
}

#page-main-wrapper {
    flex: 1;
    width: 100%;
    display: flex;
    padding: map-get($paddings, "main");
    
    

    @media(min-width: map-get($breakpoints, "x-large")){
        width: 85%;
        margin-left: auto;
        margin-right: auto;
    }

    @media(max-width: map-get($breakpoints, "large")){
        flex-direction: column;
    }
}

// CONTENT LAYOUT COMPONENTS
.sidebar-placeholder{

    width: 25%;
    height: 100%;
    padding: 0 $gap-size 0 $gap-size;

    @media(max-width: map-get($breakpoints, "large")){
        width: 100%;
        padding-bottom: $gap-size;
    }
}

.sidebar{
    // max-width: 280px;
    
    background: map-get($bg-colors, "section");
    border-radius: 6px;
    border: 1px solid $border-color;
    box-shadow: $base-shadow;
   
}

.sidebar-header, .content-header{
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
    padding: map-get($paddings, "sidebar-title");
}

.header-info-wrapper{
    padding: 0;
    margin: 0;
}

.content-placeholder{
    flex: 1;
    height: 100%;
    padding: 0 $gap-size 0 $gap-size;

    @media(max-width: map-get($breakpoints, "large")){
        width: 100%;
        padding-top: $gap-size;
    }
}

.content{
    
    background: map-get($bg-colors, "section");
    padding: map-get($paddings, "content");
    border-radius: 6px;
    border: 1px solid $border-color;
    box-shadow: $base-shadow;

    @media(max-width: map-get($breakpoints, "large")){
        flex: 0;
    }
}

ul.groups{
    list-style-type: none;
    margin: 0;
    padding: 0.75rem;
    border: 1px solid $border-color;

    li {
        display: flex;
        justify-content: space-between;
        color: #495057;
        font-family: map-get($body-font-sm, "family");
        line-height: map-get($body-font-sm, "line-height");
        font-weight: map-get($body-font-sm, "weight");
        font-size: map-get($body-font-sm, "size");

        .group-item {
            padding: map-get($paddings, "group-item");
            display: inline-block;
            height: 100%;
            flex-grow: 1;

            &:hover{
                color: map-get($brand-colors, "secondary");
                cursor: pointer;
            }

            &.active{
                color: map-get($brand-colors, "secondary");
                font-weight: 600;
                transition: font-weight 0.2s ease;
            }
        }
    }
}

div.sidebar-btn-wrapper{
    border-top: 1px solid $border-color;
    padding: 1.25rem 0.75rem 0.75rem 0.75rem;
    display: flex;
    justify-content: flex-end;
}

// TABS
.nav-tabs{
    display: flex;
    list-style-type: none;
    padding: 0;
    border: none;
    border-bottom: 2px solid #eee;

    .tab {
        padding: map-get($paddings, "tab");
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        width: 110px;
        text-align: center;
        border: 1px solid transparent;
        cursor: pointer;

        &.active{
            color: #3980c0;
            background-color: rgba(57,128,192,.3);
            border-bottom: 3px solid #3a80c0;
            margin-bottom: -1;
            cursor: default;
        }
    }
}

// FOOTER
#page-footer{
    height: 60px;
    background: map-get($bg-colors, "footer");
    
    .footer-wrapper{
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 85%;
        margin-left: auto;
        margin-right: auto;
        color: #74788d;
    }
    
}

#test-content{
    border: 1px solid red;
    background: #fff;
    padding: 24px;
    display: flex;
    justify-content: space-between;
}

.test-buttons{
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-width: 200px;
    border: 1px solid red;
}