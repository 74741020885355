.content-table {

    font-size: map-get($body-font-sm, "size");
    line-height: map-get($body-font-sm, "line-height");
    font-weight: map-get($body-font-sm, "weight");
    width: 100%;
    color: #495057;
    border-spacing: 0;
    
    tr {
        
        &:nth-child(odd){
            background-color: #f8f9fa;
        }
    }


    th{
        text-align: left;
        background-color: rgb(248, 249, 250);
        padding: 12px;
        vertical-align: baseline;
        font-weight: 400;

        .sort{
            float: right;
            opacity: .5;
        }
    }

    td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        a {
            text-decoration: underline;
            &:hover{
                cursor: pointer;
            }
        }
    }

    td.hoverable{
        width: 10px;
        padding: 0.75rem 0;
        cursor: pointer;
    }

    td, th {

        &.only-on-wide-screen {
            display: none;
            @media(min-width: map-get($breakpoints, "x-large")){
                display: table-cell;
            }
        }

        padding: 0.75rem 0.75rem;
        max-width: 150px;
        border: 1px solid $border-color; 

        // &:nth-last-child(2){
        //     width: 150px;
        // }

        // &:nth-last-child(3){
        //     width: 100px;
        // }

        // &:nth-last-child(4){
        //     width: 100px;
        // }

        // &:nth-child(1){
        //     width: 150px;
        // }



    }
}