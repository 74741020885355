.filter-details{
    display: flex;
    font-size: map-get($body-font-sm, "size");
    font-weight: map-get($body-font-sm, "weight");
    line-height: map-get($body-font-sm, "line-height");
    padding: 0.75rem;
    flex-direction: row;
    justify-content: space-between;

    .filters-section{
        height: 180px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .checkboxes{
        display: flex;
        flex-direction: column;

        .checkbox-container{
            margin-right: 0.5rem;
        }
    }
}