.login-page{
    background: url(/images/auth-bg-1.jpg) rgba(255, 0, 150, 0.3);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.login-overlay{
    background-color: #003032;
    z-index: 1;
    position: fixed;
    inset: 0;
    height: 100%;
    width: 100%;
    inset: 0;
    opacity: .7;
}

.login-header{
    height: 128px;
    color: rgba(white, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
}

.auth-dialog-wrapper{
    flex: 1;
    z-index: 2;
}

.auth-dialog{
    position: relative;
    display: flex;
    background-color: #fff;
    width: 420px;
    height: 500px;
    border: 1px solid $border-color;
    flex-direction: column;
    border-radius: calc(.4rem - 1px);
    padding: 1.5rem;
    text-align: center;
    max-width: 480px;

    .clients-view-header{
        margin-bottom: 10px;
    }

    .clients-view-content{
        max-height: 350px;
        overflow: auto;
        padding: 10px 20px;
    }

    & > p {
        font-size: map-get($body-font-sm, "size");
        font-weight: map-get($body-font-sm, "weight");
        line-height: 22.4px;
        margin-top: 0;
    } 
}

.auth-dialog > form  {
    justify-content: center;
}

.auth-dialog > form .field {
    flex-direction: column;
    align-items: flex-start;

    input {
        width: 100%;
        text-align: left;
    }

    label {
        min-width: 0;
        padding:0.25rem 0;
    }

    label[for=password]{
        display: flex;
        justify-content: space-between;
    }
} 

.auth-dialog > form .submit-field{
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 1rem;
    align-items: flex-end;

    a {
        align-self: flex-end;
        text-decoration: none;
        color: white;
    }
}

.login-footer{
    width: 100;
    width: 60vw;
    color: rgba(white, 0.5);
    padding: 1.5rem;
    text-align: center;
    z-index: 1;
    font-size: map-get($body-font-sm, "size");
}

.bottom-center{

    position: absolute;
    bottom: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
    display: block;

    p {
        justify-self: center;
        
        a{
            color: map-get($brand-colors, "secondary");
            margin: 0 0.5rem;
            text-decoration: underline;
        }
    }
}

// SELECT CLIENT
.client-button{
    display: block;
    border: 1px solid transparent;
    background-color: map-get($brand-colors, "secondary");
    color: white;
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    margin: 0.4rem 0;
    display: flex;
    justify-content: space-between;

    &:nth-child(1){
        margin-top: 0;
    }

    &:nth-last-child(1) {
        margin-bottom: 0;
    }

    &:hover{
        background-color: darken(map-get($brand-colors, 'secondary'), 5);
    }
}