// VARIABLES
$base-font: 'Be Vietnam Pro', sans-serif;
$border-color: hsl(0,0%,95%);

$paddings: (
    "header" : 0 10px,
    "bar" : 20px 10px,
    "main" : 20px 0 60px 0,
    "group-item": 6px 12px,
    "sidebar-title": 0 0 20px 0,
    "content" : 20px,
    "content-section" : 10px 0 10px 0,
    "tab": 8px 16px
);

$breakpoints: (
    "small": 500px,
    "medium": 815px,
    "large": 990px,
    "x-large": 1500px
);

$bg-colors: (
    "body" : hsl(0,0%,95%),
    "section" : #fff,
    "header" : #003032,
    "footer" : #fff
);

$brand-colors: (
    "primary" : #003032,
    "secondary": #3980c0,
    "tertiary": rgba(57,128,192,.3)
);

$colors: (
    "error" : #fa6374,
    "success": #33A186,
    "disabled": hsl(0,0%,90%),
    "disabled-txt": hsl(0,0%,75%)
);

$body-font: (
    "family" : $base-font,
    "size" : 0.875rem,
    "weight" : 400,
    "line-height": 1.6,
    "color" : #495057
);

$body-font-sm: (
    "family" : $base-font,
    "size" : 0.8rem,
    "weight" : 300,
    "line-height": 1.6,
    "color" : #495057
);

$base-shadow: 0 2px 3px #e6e8eb;
$gap-size: 10px;