.modal-backdrop{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: #000;
    opacity: .5;
}

.loading-state{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    color: hsl(0,0%,80%);
    display: flex;
    align-items: center;
    flex-direction: column;

    p {
        font-size: 28px;
        margin: 1rem;
        font-weight: 600;
    }
}

.modal-dialog{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    display: flex;
    background-color: #fff;
    width: 450px;
    border: 1px solid $border-color;
    height: 500px;
    flex-direction: column;
    border-top-left-radius: calc(.4rem - 1px);
    border-top-right-radius: calc(.4rem - 1px);
    margin-bottom: 24px;

    &.for-details{
        height: 90%;
    }

    &[name=settings]{
        height: 650px;
    }

    &.wide{
        @media(min-width: map-get($breakpoints, "medium")){
            width: 768px;
        }
    }

}

.close {
    color: #333;
    z-index: 4;
    &:hover{
        cursor: pointer;
        color: black;
    }
}

.modal-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1.6;
    padding: 1rem;
    border-bottom: 1px solid $border-color;

    .icon {
        margin-right: 0.75rem;
    }

    .warning {
        color: #fa0;
    }

    p {
        font-size: 20px;
        font-weight: 600;
        margin-left: 0.5rem;

    }
}

.modal-header-title{
    display: flex;
    width: max-content;
    justify-content: space-between;
    align-items: center;
}

.modal-content{
    padding: 24px;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: white;
    
}

.modal-content-body{
    flex: 1;
    overflow-y: auto;
    height: 100%;

    .name-section{
        margin-bottom: 1.5rem;
    }
}

.modal-content-footer{
    margin-top: 0.75rem;
    display: flex;
    justify-content: flex-end;

    &-buttons{
        display: flex;

        button {
            margin: 0 4px;
        }
    }
}

