.content-filters{

    display: flex;
    width: 100%;
    justify-content: space-between;
    
    @media(max-width: map-get($breakpoints, "x-large")){
        flex-direction: column;
    }
}

.content-filters * {
    font-size: map-get($body-font-sm, "size");
    font-weight: map-get($body-font-sm, "weight");
}

.button-wrapper{
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid $border-color;
    padding-bottom: 0.75rem;

    @media(min-width: map-get($breakpoints, 'x-large')){
        padding-right: 0.75rem;
    }
}

.filter-section{

    min-width: max-content;
    margin-bottom: 1.5rem;

}

.filter-section.checkboxes{

    margin-right: 1rem;

    .checkboxes-wrapper{
        display: flex;
        margin: 0.75rem 0;

        .checkbox-container{
            margin-right: 0.75rem;
        }
    }

    @media(max-width: map-get($breakpoints, "x-large")){
        display: flex;
        width: 100%;
        margin: 0.75rem 0;

        .checkboxes-wrapper{
            display: flex;
            justify-content: flex-end;
            flex: 1;
            
            .checkbox-container{

                margin-right: 0.75rem;

                &:nth-child(1){
                    margin-right: 0.75rem;
                }
            }
        }

        & > label {
            width: 75px;
            text-align: center;
        }
    }
}

.filter-section.inputs form {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;

    button.add{
        margin-left: 1.5rem;
    }

    @media(max-width: map-get($breakpoints, "medium")){
        flex-direction: column;
        width: 100%;
        min-height: 140px;
        text-align: right;
        align-items: flex-end;

        button.add{
            margin-right: 0.75rem;
        }

    }

    .field{
        width: max-content;
        padding: 0;
        position: relative;
        flex: 1;

        label {
            text-align: center;

            @media(max-width: map-get($breakpoints, "medium")){
                width: 75px;
                margin-top: 0.75rem;
            }
            
            &.only-mobile{
                display: none;

                @media(max-width: map-get($breakpoints, "medium")){
                    display: inline-block;
                }
            }
        }

        @media(max-width:map-get($breakpoints, "medium")){
            margin-bottom: 0.5rem;
            width: 100%;
            display: flex;
            flex-direction: row;
        }

        input {

            text-align: left;

            &.search-input{
                margin: 0 0.75rem;

                // MIN WIDTH LOGIC
                @media(min-width:map-get($breakpoints, "medium")){
                    margin-right: 0;
                }

            }

            @media(max-width: map-get($breakpoints, "medium")){
                height: 45px;
            }

        }
        
        input[type=date]{
            padding-right: 0.75rem;
            margin: 0 0.75rem;
        }

        .search-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1.5rem;

            @media(min-width:map-get($breakpoints, "medium")){
                right: 0.75rem;
            }
        }
    }
}