.form{

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .field{
        padding: 0.5rem 0;
        width: 100%;
        display: flex;
        align-items: center;

        @media(max-width: map-get($breakpoints, "medium")){
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .field > .wrapper {
        display: flex;
        width: 100%;

        label {
            margin-top: 0.5rem;
            width: 80px;
        }

        .input-container {

            flex: 1;

            .row {
                display: flex;
                position: relative;

                input {
                    width: 100%;
                }
            }
        }
    }


    .field input, .field select {
        padding: 0.47rem 2.25rem 0.47rem 0.75rem;
        outline: none;
        border: 1px solid #e2e5e8;
        border-radius: 4px;
        line-height: 1.6;
        width: 100%;
    }

    .field input::placeholder{
        color: #adb5bd;
    }
    
    .field input[type=color]{
        padding: 0;
        width: 30px;
        background-color: #fff;
        box-sizing: content-box !important;
        padding: 6px;
    }

}

.form-select{
    display: block;
    padding: 7.52px 12px;
    width: 80%;
    font-weight: 400;
    line-height: 1.6;
    color: #495057;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23384861' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #e2e5e8;
    border-radius: .25rem;
    appearance: none;

    &:focus {
        border-color: #cbced1;
        outline: 0;
        box-shadow: 0 0 0 0.15rem rgb(57 128 192 / 25%);
    }
}

.checkbox-container{
    display: flex;
    align-items: center;
}

.checkbox-container label {
    position: relative;
    cursor: pointer;
    display: flex;
}

.checkbox-container input[type=checkbox]{
    cursor: pointer;
    opacity: 0;
    position: absolute;
    width: max-content;
}

.checkbox-container label::before{
    content: '';
    width: 1em;
    height: 1em;
    border-radius: .25em;
    border: .05em solid #e2e5e8;
    margin-right: .5em;
    margin-top: .25em;
}

.checkbox-container label:hover::before, .checkbox-container input[type=checkbox]:hover + label::before {
    cursor: pointer;
}

.checkbox-container input[type=checkbox]:focus + label::before {
    border: .05em solid #e2e5e8;
}

.checkbox-container input[type=checkbox]:checked + label::before{
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e") #3980c0;
    border: 1px solid #3980c0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    transition: all 0.3s ease;
}

.validated{

    &.success {
        
        .msg{
            color: map-get($colors, "success")
        }

        .icon {
            color: map-get($colors, "success");
        }

        input, select {
            border: 1px solid map-get($colors, "success");
        }

        .checkbox-container label::before{
            border: .05em solid map-get($colors, "success");
        }
    
        .checkbox-container input[type=checkbox]:focus + label::before {
            border: .05em solid map-get($colors, "success");
        }
    }

    &.error {

        .msg{
            color: map-get($colors, "error");
        }

        .icon {
            color: map-get($colors, "error")
        }

        input, select {
            border: 1px solid map-get($colors, "error");
        }

        .checkbox-container label::before{
            border: .05em solid map-get($colors, "error");
        }
    
        .checkbox-container input[type=checkbox]:focus + label::before {
            border: .05em solid map-get($colors, "error");
        }
    }

    .icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        box-sizing: content-box;
        padding: 9px 0.47rem;
    }

    .msg {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 87.5%;
        text-align: left;
        margin-bottom: 0;
    }
}



