.flip-list-move {
    transition: transform 0.5s;
}

.mark{
    background-color: rgba(57,128,192,.3);
}

.ghost {
    background: hsl(0,0%,80%);
    opacity: 0.6;
    color: hsl(0,0%, 70%);
}

.drag-item{
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid $border-color;
    padding: 0.25rem;
    cursor: grab;

    .cb {
        cursor: pointer;
    }

    & > * {
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: map-get($body-font, "color");

    }
}

.disabled{
    background-color: map-get($colors, "disabled");

    & * {
        color: map-get($colors, "disabled-txt");
    }

}